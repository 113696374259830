@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400&display=swap');

$font-family: (
    primary: ('Work Sans', sans-serif),
    code: (SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier, monospace)
);

/* html, body
------------------------- */
html,
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
html {
    font-size: 62.5%;
    font-family: map-get($font-family, primary);
}
body {
    font-size: 1.6rem;
    color: map-get($color, midnight);
}

/* headings
------------------------- */
h1,
h2,
h3 {
    margin: 0;
    font-weight: 600;
}
h1,
h2 {

    &.has-icon .icon svg {
        width: 1em;
        height: 1em;
    }
}
h1 {
    font-size: 2em;
}
h2 {
    font-size: 1.5em;
}
h3 {
    font-size: 1.17em;
}
.sub-heading {
    margin: -0.75em 0 1em;
    line-height: 1.2em;
    @include text-small;
    color: map-get($color, dark-gray);
}

/* formatting
------------------------- */
p {
    margin-bottom: 1rem;
    line-height: 1.2em;
}
i,
em {
    font-style: italic;
}
code,
pre,
samp {
    line-height: 1.2em;
    @include text-small;
    font-family: map-get($font-family, code);
}
.line-height {
    line-height: 1.2em;
}
.text-small {
    @include text-small;
}
.text-muted {
    color: map-get($color, dark-gray);
}
.readable-text {
    max-width: 35em;
    white-space: pre-wrap;
}
.capitalize {
    text-transform: capitalize;
}
.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* text alignment
------------------------- */
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.text-justify {
    text-align: justify;
}

/* text colors
------------------------- */
.text-red {
    color: map-get($color, red);
}
