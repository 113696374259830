.user-lookup {

    .field-group {

        input {
            max-width: 30rem;
            margin-right: 1rem;
        }
    }
}
