.messages {

    .message-threads,
    .message-thread {

        .card {
            position: relative;
            overflow: hidden;
            padding: 0;
        }
        .header {
            position: absolute;
            top: 0;
            width: 100%;
        }
        .feed {
            overflow-y: scroll;
            max-height: calc(100vh - 10.2rem);
        }
    }
    .message-threads {
        max-width: 35rem;
        max-height: calc(100vh - 10.2rem);
        margin-right: 2rem;

        .header {
            padding: 1rem;
            font-weight: 600;
        }
        .feed {
            padding-top: 3.6rem;
        }
        .row {
            display: flex;
            padding: 1rem;
            border-top: 0.1rem solid map-get($color, mist);

            &:hover {
                text-decoration: none;
            }
            .image {
                display: block;
                min-width: 5.5rem;
                min-height: 5.5rem;
                border: 0.1rem solid map-get($color, white);
                border-radius: 0.4rem;
            }
            .meta {
                width: 100%;
                min-width: 0; // required for ellipsis
                margin-left: 1rem;
                padding-right: 1rem;

                &.has-unread {
                    border-right: 0.2rem solid map-get($color, blue);

                    .business-name {
                        font-weight: 600;
                    }
                }
                .business-name,
                .message {
                    padding: 0.2rem 0;
                    @include text-small;
                }
                .business-name {
                    margin-bottom: 0.2rem;
                    color: map-get($color, black);
                }
                .time-distance {
                    font-size: 0.6875em;
                    color: map-get($color, sea);
                }
            }
            &.active {
                background-color: scale-color(map-get($color, mist), $lightness: 30%);

                .meta {
                    border-right: 0.2rem solid map-get($color, sea);
                }
                .time-distance {
                    text-shadow: 0 0 0 map-get($color, sea);
                }
            }
        }
    }
    .message-thread {

        .header {
            padding: 2rem 2rem 0.7rem;

            a {
                display: inline-flex;
                text-decoration: none;

                &:hover .view-profile .label {
                    text-decoration: underline;
                }
            }
            .image {
                min-width: 4.5rem;
                min-height: 4.5rem;
                border-radius: 0.4rem;
            }
            .meta {
                min-width: 0; // required for ellipsis
                margin-left: 1rem;

                .business-name {
                    min-height: 2.6rem;
                    padding: 0.4rem 0;
                    color: map-get($color, midnight);
                }
                .view-profile {
                    font-size: 0.6875em;

                    .icon svg {
                        margin: -0.2rem 0 0 0.5rem;
                        width: 1rem;
                        height: 1rem;
                    }
                }
            }
        }
        .feed {
            padding: 9.5rem 2rem 7.4rem;
        }
        .row {
            display: flex;
            margin-bottom: 1rem;

            .image {
                min-width: 3.4rem;
                min-height: 3.4rem;
                border-radius: 10rem;
            }
            .meta {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin: 0 0 0 1rem;

                .message-body {
                    display: flex;
                    align-items: center;
                    padding: 0.9rem 1.2rem;
                    @include text-small;
                    color: map-get($color, black);
                    background-color: map-get($color, mist);
                    border-radius: 1rem;
                    border-top-left-radius: 0;
                }
                .time-distance {
                    display: block;
                    margin-top: 0.5rem;
                    font-size: 0.6875em;
                    color: map-get($color, sea);
                }
            }
        }
        .row.reverse {
            flex-direction: row-reverse;

            .meta {
                align-items: flex-end;
                margin: 0 1rem 0 0;

                .message-body {
                    @include text-white;
                    background-color: map-get($color, sea);
                    border-top-left-radius: 1rem;
                    border-top-right-radius: 0;
                }
            }
        }
    }
    .composer {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 1rem 2rem 2rem;

        .field-group {
            margin: 0 1rem 0 0;

            textarea {
                min-height: 3.2rem;
                max-height: 8em;
            }
        }
        .actions {
            display: flex;
            align-items: flex-end;

            .btn {
                min-width: 6rem;
                margin-bottom: 0.3rem;
            }
        }
    }
    .message-threads .header,
    .message-thread .header,
    .composer {
        background-color: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(1.5rem);
    }
}
