.indicator-dot,
.indicator-count {
    background-color: map-get($color, midnight);
    border-radius: 10rem;
}

.indicator-dot {
    display: inline-block;
    width: 1rem;
    height: 1rem;
}

.indicator-count {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 1.5rem;
    min-height: 1.5rem;
    padding: 0.1rem;
    font-size: 0.6875em;
    font-weight: 600;
    color: map-get($color, white);
    border: 0.1rem solid map-get($color, white);
}

.has-indicator {
    position: relative;
}
