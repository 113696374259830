.profile-content {

    .profile-image {
        min-width: 30rem;
        margin-right: 2rem;

        .metadata-image {
            border-radius: 1rem;
        }
    }
    .body {

        .category {
            font-weight: bold;
        }
    }
}
