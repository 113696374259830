.service-add-edit {

    .card {
        max-width: 42rem;
        margin: 0 auto;
    }
    .confirm-message {
        color: map-get($color, red);
    }
}
