.file-drop {
    padding: 0.4rem 0.8rem;
    @include text-tiny;
    color: map-get($color, dark-gray);
    border: 0.1rem dashed map-get($color, light-gray);
    border-radius: 0.3rem;
    outline: none;
    user-select: none;

    &:hover,
    &:focus {
        color: map-get($color, blue);
        border-color: map-get($color, blue);
        cursor: pointer;

        .icon svg {
            fill: map-get($color, blue);
        }
    }
    &.disabled {
        pointer-events: none;
    }
    .cols {
        min-height: 2rem;
        align-items: center;
    }
    .col-label {
        min-width: 0;
        padding-right: 0.5rem;
    }
    .label {
        padding: 0.1rem 0;
    }
    .icon {
        margin-right: -0.1rem;

        svg {
            fill: map-get($color, gray);
        }
        &:hover svg {
            fill: map-get($color, midnight);
        }
        &.remove {
            margin-right: -0.2rem;

            svg {
                width: 1.6rem;
                height: 1.6rem;
            }
        }
    }
    .image-preview {
        margin: 0.5rem 0 0.4rem;

        img {
            width: 100%;
            border-radius: 0.2rem;
        }
    }
    .error {
        color: map-get($color, red-tinted);
    }
}

.has-error .file-drop {
    border-color: map-get($color, red-tinted);
}
