.list-expandable {

    > .item {
        margin-bottom: 1rem;
        padding: 0;

        > .header {
            display: flex;
            align-items: center;
            padding: 1.5rem;
            cursor: pointer;

            > .indicator-dot {
                margin-right: 1rem;
            }
            > .heading {

                a {
                    text-decoration: none;
                }
            }
            > .expand-icon {
                margin-left: 1.5rem;
            }
        }
        > .body {
            padding: 0 1.5rem 1.5rem;
        }
        > .footer {
            padding: 0 1.5rem 1.5rem;
        }
    }
}
