.public {

    &.app {
        min-width: 0;
    }

    @media (max-width: 1440px) {

        .home {

            .introduction {
                padding-bottom: 15rem;
            }
        }
    }
    @media (max-width: 576px) {

        > .header {

            > .cols {
                flex-direction: column;
            }
            .logo {
                width: 100%;
            }
            .col-nav {
                margin: 2rem 0;

                .nav-bar {
                    text-align: center;
                }
            }
        }
        .home {

            .introduction {
                background-position: unset;
            }
            > .introduction {
                padding: 3rem 2rem 7.5rem;
            }
            .body {

                > .introduction {
                    padding: 8rem 2rem;
                }
            }
        }
        form {

            .btn-group .btn {
                width: 100%;
            }
        }
        .sign-in {

            .btn-group {
                flex-direction: column-reverse;

                .forgot-password {
                    margin-top: 2rem;
                    text-align: center;
                }
                .btn {
                    margin: 0;
                }
            }
        }
    }
}

.policies {

    @media (max-width: 992px) {

        .policies-disclaimer,
        .policies-privacy,
        .policies-terms {

            &.bounds {
                padding: 0;
            }
        }
        .settings-nav {
            display: none;
        }
        .pi-table {

            th,
            td {
                padding: 0.5rem;
            }
        }
    }
}

.public-footer {

    @media (max-width: 576px) {

        .nav {

            a {
                display: block;
                margin: 0 0 2rem 0;

                &:last-child {
                    margin: 0;
                }
            }
        }
    }
}

.public-profile {

    @media (max-width: 576px) {

        .card {
            padding: 3rem;
        }
    }
}
