.side-nav {
    display: flex;
    flex-direction: column;
    width: 22rem;
    background-color: map-get($color, white);

    .nav-section {

        > .header {
            padding: 1.5rem 2.5rem;

        }
        > .nav-link {
            display: flex;
            align-items: center;
            padding: 0.5rem 0.8rem 0.5rem 2.5rem;
            color: map-get($color, gray);

            &:hover {
                text-decoration: none;
                color: map-get($color, sea-tinted);
                background-color: scale-color(map-get($color, mist), $lightness: 50%);

                .icon svg {
                    fill: map-get($color, sea-tinted);
                }
            }
            &.active {
                color: map-get($color, sea);

                & + .sub-nav {
                    display: block;
                }
                .icon svg {
                    fill: map-get($color, sea);
                }
                .label {
                    border-right: 0.2rem solid map-get($color, sea);
                }
            }
            .icon {
                min-width: 2rem;
                margin-right: 1rem;
                text-align: center;

                svg {
                    fill: map-get($color, gray);
                }
            }
            .label {
                width: 100%;
                padding: 0.5rem 0.5rem 0.5rem 0;
            }
        }
    }
    .sub-nav {
        display: none;

        .nav-link {
            display: block;
            padding: 0.5rem 0.8rem 0.5rem 5.5rem;
            color: map-get($color, gray);

            &:hover {
                text-decoration: none;
                color: map-get($color, dark-gray);
            }
            &.active {
                color: map-get($color, lead);
            }
        }
    }
    .nav-footer {
        padding: 2rem;
        margin-top: auto;
    }
}
