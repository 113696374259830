.loading {

    &.has-padding {
        padding: 10vh 0;
    }
    .sk-circle-fade {
        margin: 0 auto;

        .sk-circle-fade-dot:before {
            background-color: map-get($color, sea);
        }
    }
}
