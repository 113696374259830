.badge {
    padding: 0.4rem 0.8rem;
    font-size: 0.75em;
    font-weight: 600;
    color: map-get($color, white);
    background-color: map-get($color, midnight);
    border-radius: 0.2rem;

    &-success {
        background-color: map-get($color, green);
    }
    &-danger {
        background-color: map-get($color, red-tinted);
    }
    &-warning {
        background-color: scale-color(map-get($color, gold), $lightness: -10%);
    }
    &-info {
        background-color: scale-color(map-get($color, sea), $lightness: -10%);
    }
    &-inverse {
        color: map-get($color, midnight);
        background-color: map-get($color, mist);
    }
}

a.badge {
    background-color: map-get($color, blue-tinted);

    &:hover {
        background-color: map-get($color, blue);
    }
}
