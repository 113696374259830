/* mixins
------------------------- */
@mixin text-small {
    font-size: 0.875em;
}
@mixin text-tiny {
    font-size: 0.765625em;
}
@mixin text-white {
    color: map-get($color, white);
    text-shadow: 0 0 0 map-get($color, white);
}
