$color: (
    red: #f03545,
    red-tinted: #dc3545,

    hemp: #faf6ef,
    gold: #ffc107,

    green: #28a745,
    mint: #35c580,

    mist: #e0f8fa,
    light-sea: #9be9ee,
    sea: #3dccc7,
    sea-tinted: #59b6b4,
    dusk: #7dc8cc,

    ice: #e4f0ff,
    sky: #80bdff,

    blue: #007bff,
    blue-tinted: #0065e5,

    midnight: #1B2735,

    white: #ffffff,
    smoke: #f2f2f2,

    light-gray: #ced4da,
    gray: #a5aaae,
    dark-gray: #7c7f83,

    slate: #3e4042,
    lead: #222222,
    black: #000000
);
