@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/themes/light.css';
@import '~tippy.js/themes/light-border.css';
@import '~tippy.js/themes/material.css';
@import '~tippy.js/themes/translucent.css';

.tippy-box[data-theme~='small'] {
    font-size: 0.6875em;
    @include text-white;
}
