.license-gate {

    .content-message {
        padding: 0 0 2rem 0;
    }
    .card {
        max-width: 66rem;
        margin: 0 auto;
    }
}

.profile-gate {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-top: 12rem;
    background-color: rgba(255, 255, 255, 0.9);

    .content-message {
        padding: 0 0 2rem 0;
    }
}
