header {
    display: flex;
    align-items: center;

    > .cols {
        width: 100%;
    }
    .logo {
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22rem;
        height: 5rem;

        svg {
            width: 17rem;
            fill: map-get($color, sea);
        }
    }
    .col-nav {
        display: flex;
        align-items: center;
        margin-right: 2.5rem;
    }
    &.has-user {
        border-bottom: unset;

        .col-nav {
            margin-left: 2.5rem;
            border-bottom: 0.2rem solid map-get($color, mist);
        }
    }
}
