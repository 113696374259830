.search-results {

    .results {

        .select-source {
            display: flex;
            align-items: center;
            margin-bottom: 2rem;

            label {
                margin-right: 1rem;
                font-weight: bold;
                color: map-get($color, sea);
            }
            .react-select {
                min-width: 15rem;

                .select-option {
                    align-items: center;

                    .icon {
                        margin-right: 0.6rem;

                        svg {
                            width: 1.4rem;
                            height: 1.4rem;
                            margin-top: -0.1rem;
                            fill: map-get($color, mint);
                        }
                    }
                }
            }
        }
    }
}
