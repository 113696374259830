.product-home {

    .image-group {
        flex: 15;

        .image img {
            width: 100%;
            background-color: map-get($color, white);
            border: 0.2rem solid map-get($color, white);
        }
        .thumbnails {
            flex: 3;
            margin-right: 2rem;

            .image {
                margin-bottom: 1rem;
            }
        }
        .image-large {
            flex: 17;
        }
        .image-placeholder img {
            width: 100%;
            border: 0.2rem solid map-get($color, white);
        }
    }
    .details {
        flex: 12;
        margin-left: 2rem;

        .category {
            font-weight: bold;
        }
    }
    .side-bar {
        flex: 6;
        max-width: 22rem;
        margin-left: 2rem;
        border-left: 0.2rem solid map-get($color, mist);

        .inner {
            padding-left: 2rem;
        }
        .profile {

            .metadata-image {
                margin-bottom: 1rem;
                border-radius: 0.6rem;
            }
            .title {
                display: block;
                font-weight: 600;
                text-align: center;
            }
        }
        .actions {
            margin-top: 1.5rem;

            .btn {
                display: block;
                width: 100%;
                margin-top: 0.8rem;
            }
        }
    }
}
