.nav-bar {
    flex: 1;
    text-align: right;

    .nav-link {
        margin-right: 2rem;

        &:last-child {
            margin-right: 0;
        }
        &:hover {

            .icon svg {
                fill: map-get($color, sea-tinted);
            }
        }
        &.active {
            text-decoration: underline;

            .icon svg {
                fill: map-get($color, sea);
            }
        }
        .icon {

            svg {
                fill: map-get($color, gray);
            }
        }
        &.messages-link {

            .indicator-count {
                top: -0.6rem;
                right: -0.9rem;
            }
            &.active .indicator-count {
                display: none;
            }
        }
    }
}
