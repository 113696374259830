.react-select {
    @include text-small;

    .react-select__control {
        min-height: 2.9rem;
        border-color: map-get($color, light-gray);
        border-radius: 0.3rem;

        &:hover {
            border-color: map-get($color, light-gray);
        }
        &--is-focused {
            border-color: map-get($color, sky);
            box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);

            &:hover {
                border-color: map-get($color, sky);
            }
        }
    }
    .react-select__menu {
        z-index: 2;
    }
    .react-select__value-container {
        padding-right: 0.6rem;
        padding-left: 0.6rem;
    }
    .react-select__placeholder {
        @include text-small;
        color: map-get($color, gray);
    }
    .react-select__single-value {
        padding-bottom: 0.1rem;
        color: map-get($color, midnight);
    }
    .react-select__input {

        > input:focus {
            box-shadow: none;
        }
    }
    .react-select__indicator {
        padding: 0.3rem 0.4rem;

        svg {
            height: 1.8rem;
            width: 1.8rem;
        }
    }
    &.floating {

        .react-select__control {
            border-color: map-get($color, smoke);

            &:not(.react-select__control--is-focused) {
                box-shadow: 0 0.1rem 1rem rgba(21, 25, 90, 0.05),
                            0 1rem 10rem rgba(21, 25, 90, 0.1);
            }
        }
    }
}
