html,
body {
    background-color: map-get($color, white);
}
html {
    box-sizing: border-box;
    overflow-y: scroll;
}
body {
    height: auto;
}

/* layout
------------------------- */
.app {
    min-width: 104rem;
}
.content {
    max-width: 140rem;
    margin: 0 auto;
}
.view {
    min-height: calc(100vh - 5.2rem);
}
.section {
    margin-bottom: 1.5rem;
}
.section-spacer {
    height: 2vw;
    min-height: 2rem;
    max-height: 3rem;
}
.content-spacer {
    height: 4vw;
    min-height: 4rem;
    max-height: 6rem;
}
.bounds {
    margin-right: auto;
    margin-left: auto;
    padding: 0 2.5rem;
    transition: padding 0.2s;
}
.container {
    @extend .bounds;
    padding: 2rem 2.5rem;
}
.cols {
    display: flex;

    > .col {
        flex: 1;
        padding: 0;

        &.col-2 { flex: 2 }
        &.col-3 { flex: 3 }
        &.col-4 { flex: 4 }
    }
}

/* helpers
------------------------- */
.centered {
    margin-right: auto;
    margin-left: auto;
}
.float {
    &-left { float: left }
    &-right { float: right }
}
