a {
    text-decoration: none;
    color: map-get($color, blue-tinted);

    svg {
        fill: map-get($color, blue-tinted);
    }
    &:hover {
        text-decoration: underline;
        color: map-get($color, blue);

        svg {
            fill: map-get($color, blue);
        }
    }
    &.incognito {
        color: map-get($color, midnight);
    }
    &.light {
        color: map-get($color, white);
    }
}
