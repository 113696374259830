.nav-tabs {

    > .nav-tab {
        display: inline-block;
        margin-right: 2rem;
        padding-bottom: 0.8rem;
        color: map-get($color, dark-gray);
        border-bottom: 0.2rem solid transparent;
        text-decoration: none;

        &:last-child {
            margin-right: 0;
        }
        &:hover {
            color: map-get($color, lead);
            border-bottom-color: map-get($color, light-gray);
        }
        &.active {
            color: map-get($color, black);
            border-bottom-color: map-get($color, sea);
        }
    }
}
