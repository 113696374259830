/* badge group
------------------------- */
.badge-group {

    .badge {
        margin-right: 0.5rem;

        &:last-child {
            margin-right: 0;
        }
    }
}

/* basic list
------------------------- */
ul.basic-list {

    > li {
        margin: 0 0 1rem 5rem;
        line-height: 1.2em;
        list-style-type: disc;
    }
}

/* button group
------------------------- */
.btn-group {

    .btn {
        margin-left: 1rem;

        &:first-child {
            margin-left: 0;
        }
    }
}

/* card
------------------------- */
.card {
    padding: 1rem;
    background-color: map-get($color, white);
    border-radius: 0.5rem;
    box-shadow: 0 0.1rem 1rem rgba(21, 25, 90, 0.05),
                0 1rem 10rem rgba(21, 25, 90, 0.1);

    > .inner {
        padding: 1rem;
    }
}

/* card: feature card
------------------------- */
.feature-card {
    border: 0.2rem solid map-get($color, sea);

    .heading {
        margin-bottom: 1.2rem;

        .icon svg {
            fill: map-get($color, sea);
        }
    }
}

/* card: message card
------------------------- */
.message-card {
    padding: 2.5rem;
    margin: 0 auto;
}

/* content message
------------------------- */
.content-message {
    font-weight: bold;
    text-align: center;
    color: map-get($color, sea);

    &.has-padding {
        padding: 10vh 0;
    }
    &.has-less-padding {
        padding: 3vh 0;
    }
}

/* common header
------------------------- */
.common-header {
    align-items: center;
    margin-bottom: 2rem;

    &.has-border {
        padding-bottom: 2rem;
        border-bottom: 0.2rem solid map-get($color, mist);
    }
}

/* has icon
------------------------- */
.has-icon {
    display: flex;
    align-items: center;

    .icon {
        display: flex;
        margin-right: 1rem;

        svg {
            fill: map-get($color, midnight);
        }
    }
    &.has-icon-small {

        .icon {
            margin-right: 0.7rem;

            svg {
                width: 1.6rem;
                height: 1.6rem;
            }
        }
    }
}
