@mixin background-splash {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/evernug-app.appspot.com/o/public%2Fmountain-splash.svg?alt=media&token=e0b026b2-ed04-4049-af24-4b949232ca58");
    background-repeat: no-repeat;
    background-size: cover;
}

.public {
    background-color: map-get($color, mist);

    .content {
        max-width: unset;
        @include background-splash;
    }

    /* home page
    ------------------------- */
    .home {

        .introduction {
            padding: 10rem 2rem 25rem;
            text-align: center;
            @include background-splash;
            background-position: right 65%;

            h1.heading {
                max-width: 28em;
                margin: 0 auto;
                line-height: 1.2em;
            }
            h2.heading {
                margin-bottom: 1.5rem;
            }
        }
        .body {
            margin-top: -0.3rem;
            background-color: #96d8dc;

            .open-letter {
                max-width: 80rem;
                margin: 0 auto;
                padding: 0 2rem;

                h1 {
                    margin-bottom: 3rem;
                }
                h3 {
                    line-height: 1.3em;
                }
            }
        }
    }
}

/* policies
------------------------- */
.policies {

    h1, h2, h3, h4 {
        line-height: 1.2em;
    }
    h4 {
        font-weight: 600;
    }
    > .inner {
        min-height: calc(100vh - 5rem - 16rem);
    }
    .common-header {
        border-bottom-color: map-get($color, light-sea);
    }
    .pi-table {
        width: 100%;
        border: 0.1rem solid map-get($color, light-sea);

        thead {
            font-weight: bold;
            text-align: left;

            th:nth-child(1) {
                width: 35%;
            }
            th:nth-child(2) {
                width: 55%;
            }
            th:nth-child(3) {
                width: 10%;
            }
        }
        th,
        td {
            padding: 1rem;
            border: 0.1rem solid map-get($color, light-sea);
        }
    }
}

/* footer
------------------------- */
.public-footer {
    padding: 3rem 0;
    color: map-get($color, light-sea);
    background-color: map-get($color, midnight);

    .nav {
        padding-bottom: 3rem;
        text-align: center;

        a {
            margin-right: 2rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }
    .logo-wrapper {
        margin-bottom: 1.5rem;
        text-align: center;

        .logo svg {
            max-width: 17rem;
            fill: map-get($color, light-sea);
        }
    }
    .copyright {
        @include text-small;
        text-align: center;
    }
}
