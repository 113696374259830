.form-title {
    margin-bottom: 1.8rem;
}
.form-title-extended {
    margin-bottom: 2.7rem;
}
.form-container {
    max-width: 38rem;

    .btn-group {
        margin-top: 2rem;
        text-align: right;
    }
}
.field-group {
    margin-bottom: 1.2rem;

    label {
        display: block;
        margin-bottom: 0.6rem;
        @include text-tiny;
        color: map-get($color, dark-gray);
    }
    input,
    select,
    textarea {
        width: 100%;
        padding: 0.6rem 0.8rem;
        @include text-small;
        background-color: map-get($color, white);
        border: 0.1rem solid map-get($color, light-gray);
        border-radius: 0.3rem;

        &.floating {
            border-color: map-get($color, smoke);
            box-shadow: 0 0.1rem 1rem rgba(21, 25, 90, 0.05),
                        0 1rem 10rem rgba(21, 25, 90, 0.1);
        }
        &:disabled {
            color: map-get($color, midnight);
            background-color: map-get($color, smoke);
        }
        &:focus {
            outline: 0;
            border-color: map-get($color, sky);
            box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
        }
        &::placeholder {
            @include text-small;
            color: map-get($color, gray);
        }
    }
    textarea {
        min-height: 6.45em;
        max-height: 22em;
        resize: vertical;
    }
    &.has-error {

        label {
            color: map-get($color, red-tinted);
        }
        input,
        .react-select .react-select__control {
            border-color: map-get($color, red-tinted);
        }
    }
}
.field-hint {
    margin-top: 0.5rem;
    @include text-tiny;
    color: map-get($color, dark-gray);
}
.form-errors,
.field-errors {
    @include text-small;

    .error {
        margin-left: 1.5rem;
        line-height: 1.2em;
        @include text-small;
        color: map-get($color, red-tinted);
        list-style-type: disc;

        &:first-child {
            margin-top: 0.5rem;
        }
    }
}
