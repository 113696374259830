.search-bar {
    display: flex;
    align-items: center;
    flex: 2;

    &.has-focus {

        label svg {
            fill: map-get($color, sea);
        }
        input::placeholder {
            color: map-get($color, light-gray);
        }
    }
    label {
        margin-right: 1rem;
        fill: map-get($color, dark-gray);
    }
    input {
        flex: 1;
        margin-right: 2rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        border: none;
        outline: none;

        &::placeholder {
            font-style: italic;
            color: map-get($color, gray);
        }
    }
}
