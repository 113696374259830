*,
*:before,
*:after {
    box-sizing: inherit;
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}
button,
input,
select,
textarea {
    font-size: 100%;
}
input::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}
