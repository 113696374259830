.profile-grid {

    > .profiles {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(24rem, 1fr));
        grid-gap: 1rem;

        > .profile {
            padding: 0.5rem;
            border-radius: unset;

            .image {
                display: block;
                height: 18rem;
                margin-bottom: 0.5rem;
                background-image: url($photo-placeholder);
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
            }
            .metadata {
                padding: 0.5rem;
                text-align: center;
            }
            .title {
                margin-bottom: 1.2rem;
            }
            .category {
                padding-bottom: 0.5rem;
            }
        }
    }
}
