.list-attributes {

    > .item {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
        > label {
            display: inline-block;
            min-width: 25rem;
            color: map-get($color, dark-gray);
        }
    }
}
