.product-catalog,
.profile-catalog,
.service-catalog {

    .common-header {
        min-height: 3.2rem;

        .badge {
            margin: 0 0 -0.1rem 1rem;
            font-size: 1.2rem;
        }
    }
}

.product-catalog {

    .feature-card {
        max-width: unset;
        margin-bottom: 2.5rem;
    }
}
