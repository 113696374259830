.wrapper {

    > .side-nav {
        position: relative;
        padding-top: 1.2rem;
        background-color: map-get($color, hemp);
        box-shadow: 0 0.2rem 0 map-get($color, dusk);
        background-image: url("https://firebasestorage.googleapis.com/v0/b/evernug-app.appspot.com/o/public%2Fsidenav.png?alt=media&token=560bad1a-5d91-49f8-a41e-c80fa3ec2f92"),
                          linear-gradient(0deg, map-get($color, light-sea) 30%, map-get($color, hemp) 55%);
        background-position: bottom 7rem center;
        background-repeat: no-repeat;
        background-size: contain;

        .bg-shim {
            position: absolute;
            top: -5rem;
            left: 0;
            width: 22rem;
            height: 5rem;
            background-color: map-get($color, hemp);
        }
        .nav-section {

            > .nav-link:not(:hover):not(.active) {
                color: map-get($color, dark-gray);
            }
            > .nav-link:hover {
                background-color: scale-color(map-get($color, hemp), $lightness: 80%);
            }
            .icon {

                svg {
                    fill: map-get($color, dark-gray);
                }
            }
        }
    }
    .sub-nav {

        .nav-link {
            @include text-small;
            color: map-get($color, dark-gray);

            &:hover {
                color: map-get($color, slate);
                background-color: scale-color(map-get($color, hemp), $lightness: 80%);
            }
            &.active {
                color: map-get($color, black);

                .label {
                    border-right: 0.2rem dotted map-get($color, black);
                }
            }
            .label {
                display: block;
                padding-right: 0.5rem;
            }
        }
    }
    .nav-footer {
        background-color: map-get($color, dusk);

        .label {
            display: inline-block;
            margin-bottom: 0.5rem;
            font-weight: bold;
        }
    }
}
