.form-with-images {

    .card {
        max-width: 54.5rem;
        margin: 0 auto;
    }
    .images {
        min-width: 15rem;
        max-width: 15rem;
        margin-right: 1.5rem;

        .field-group {
            margin-bottom: 0.5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
        .file-drop {
            min-height: 12rem;
        }
    }
    .form-container {
        display: flex;
        flex-direction: column;
    }
    .actions {
        margin-top: auto;
        padding-top: 1rem;
        text-align: right;
    }
}
