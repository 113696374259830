.public-profile {

    .card {
        max-width: 50rem;
        margin: 0 auto;
        padding: 3rem 6rem;
        text-align: center;
    }
    .headline {
        margin-bottom: 2rem;
        font-weight: 400;
    }
    .tagline {
        margin-bottom: 2rem;
    }
    .profile-image {
        width: 15rem;
        margin: 0 auto;

        .metadata-image {
            border-radius: 0.6rem;
        }
    }
    .profile-name {
        margin-top: 0.5rem;
        margin-bottom: 2rem;
        font-weight: 600;
    }
    .actions {

        .request-access {
            margin-top: 2rem;
        }
    }
}
