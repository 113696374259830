.logout {

    .menu {
        max-width: 20rem;

        .nav {
            height: 100%;
            padding-top: 2.2rem;
            padding-left: 1.5rem;
            background-color: rgba(255,255,255,0.5);
            box-shadow: 0.2rem 0 0 rgba(255,255,255,0.5);

            .nav-link {
                display: inline-block;
                width: 100%;
                padding: 1rem;

                &.active {
                    box-shadow: 0.2rem 0 0 map-get($color, sea);
                }
            }
        }
    }
    .licenses {
        position: relative;

        .license-file {
            position: absolute;
            top: 2.8rem;
            right: 2.5rem;
        }
    }
}

.logout-bar {
    padding: 1rem 0;
    background-color: map-get($color, midnight);

    .cols {
        align-items: center;
    }
    .label {
        margin-right: 1.5rem;
        color: map-get($color, white);
    }
    .icon svg {
        width: 3rem;
        height: 3rem;
        fill: map-get($color, light-gray);
    }
    .active svg,
    .icon svg:hover {
        fill: map-get($color, sea);
    }
}
