.service-catalog {

    .service-list {
        margin-top: 3rem;

        .item {

            .title {
                line-height: 1.2em;
            }
            .body {
                padding: 2rem;
                padding-top: 0;

                .description {
                    margin-top: 1rem;
                }
            }
            .footer {
                min-height: 5.2rem;
                padding: 0 2rem 2rem;
                align-items: center;
            }
        }
        .badge {
            margin-right: 1rem;

            &.distribution {
                background-color: scale-color(map-get($color, gold), $lightness: -10%);
            }
            &.manufacturing {
                background-color: map-get($color, blue-tinted);
            }
            &.packaging {
                background-color: map-get($color, red-tinted);
            }
            &.processing {
                background-color: map-get($color, green);
            }
            &.whitelabeling {
                background-color: map-get($color, black);
            }
        }
    }
}
