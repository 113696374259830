/* character limit
------------------------- */
.character-limit {
    text-align: right;
}

/* has color
------------------------- */
div,
span {
    &.has-info {
        color: map-get($color, sea);
        &.icon svg { fill: map-get($color, sea); }
    }
    &.has-info-bg {
        background-color: map-get($color, sea);
    }
    &.has-danger ,
    &.has-error {
        color: map-get($color, red-tinted);
        &.icon svg { fill: map-get($color, red-tinted); }
    }
    &.has-danger-bg ,
    &.has-error-bg {
        background-color: map-get($color, red-tinted);
    }
    &.has-success {
        color: map-get($color, mint);
        &.icon svg { fill: map-get($color, mint); }
    }
    &.has-success-bg {
        background-color: map-get($color, mint);
    }
    &.has-warning {
        color: map-get($color, gold);
        &.icon svg { fill: map-get($color, gold); }
    }
    &.has-warning-bg {
        background-color: map-get($color, gold);
    }
}

/* icon
------------------------- */
.icon {

    &.flip-h svg {
        transform: scaleX(-1);
    }
    svg {
        width: 2rem;
        height: 2rem;
    }
}

/* images
------------------------- */
.image-as-background {
    display: inline-block;
    min-width: 5rem;
    min-height: 5rem;
    background-image: url($photo-placeholder);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.metadata-image {
    padding-top: 75%;
    background-image: url($photo-placeholder);
    background-position: center;
    background-size: cover;
}

/* separator
------------------------- */
.sep-h {
    margin: 1.5rem 0;
    border-bottom: 0.1rem solid map-get($color, mist);
}
