.btn {
    padding: 0.6rem 0.8rem;
    font-size: 1.4rem;
    font-weight: 600;
    border: 0.2rem solid transparent;
    border-radius: 0.3rem;
    box-shadow: 0 0.8rem 1.6rem rgba(21, 25, 90, 0.15);
    transition: color 0.2s, background-color 0.2s;

    &:enabled {
        cursor: pointer;
    }
    &:disabled {
        box-shadow: none;
    }
    &:focus {
        outline: 0;
        box-shadow: 0 0 0 0.3rem rgba(0,123,255,.25);
    }
    /* button sizes
    ------------------------- */
    &-large {
        padding: 0.9rem 1.2rem;
        font-size: 1.6rem;
    }
    /* solid buttons
    ------------------------- */
    &-primary,
    &-info,
    &-danger {
        color: map-get($color, white);

        &:disabled {
            background-color: map-get($color, gray);
        }
    }
    &-primary {
        background-color: map-get($color, sea);

        &:hover:enabled {
            background-color: map-get($color, mint);
        }
    }
    &-info {
        background-color: map-get($color, blue-tinted);

        &:hover:enabled {
            background-color: map-get($color, blue);
        }
    }
    &-danger {
        background-color: map-get($color, red-tinted);

        &:hover:enabled {
            background-color: map-get($color, red);
        }
    }
    /* outline buttons
    ------------------------- */
    &-outline-primary,
    &-outline-danger {
        background-color: map-get($color, white);

        &:hover:enabled {
            color: map-get($color, white);
        }
        &:disabled {
            color: map-get($color, gray);
            border-color: map-get($color, gray);
        }
    }
    &-outline-primary {
        color: map-get($color, sea);
        border-color: map-get($color, sea);

        &:hover:enabled {
            background-color: map-get($color, mint);
            border-color: map-get($color, mint);
        }
    }
    &-outline-danger {
        color: map-get($color, red-tinted);
        border-color: map-get($color, red-tinted);

        &:hover:enabled {
            background-color: map-get($color, red);
            border-color: map-get($color, red);
        }
    }
}
