.field-row,
.field-row-footer {
    display: flex;
    padding: 2rem 0;

    .meta {
        flex: 1;
        min-width: 20rem;
        padding-right: 4rem;
    }
    .form-group,
    .field-group {
        flex: 2;
        margin: 0;
    }
}
.field-row {
    border-top: 0.1rem solid map-get($color, mist);

    &:last-child {
        padding-bottom: 0;
    }
    .meta {
        @include text-small;

        label {
            display: block;
            font-weight: 600;
            color: map-get($color, sea);
        }
        .caption {
            margin-top: 0.8rem;
            @include text-small;
            color: map-get($color, dark-gray);
        }
    }
    .field-group {

        input,
        select,
        textarea,
        .file-drop,
        .react-select {
            max-width: 38rem;
        }
    }
}
.field-row-footer {

    .actions,
    .form-errors {
        max-width: 38rem;
    }
    .form-errors {
        margin-bottom: 2rem;
    }
}
