.access-requests {

    .status-filter {
        align-items: center;

        label {
            margin-right: 1rem;
            font-weight: bold;
            color: map-get($color, sea);
        }
        .field-group {
            min-width: 15rem;
            margin: 0;
        }
    }
    .list-expandable {

        .footer {

            .email-message {
                margin-top: 0.5rem;
                padding-top: 2rem;
                border-top: 0.1rem dashed map-get($color, light-gray);
            }
        }
    }
}
