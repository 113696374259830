.settings-nav {

    .card {
        width: 35rem;
        margin-right: 2rem;
        padding: 1rem 0 1.8rem;
    }
    .license-link {

        .icon svg {
            width: 1.8rem;
            height: 1.8rem;
        }
    }
}

.settings-section {

    .card {
        padding: 2.5rem;
    }
}

.settings-badges {


    .svg-badge {
        width: 20rem;
        margin-right: 5rem;
    }
    .code-snippet {
        flex: 1;

        textarea {
            max-width: 100%;
            @include text-tiny;
            font-family: map-get($font-family, code);
        }
    }
}
